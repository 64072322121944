import axios from 'axios';
import { UPDATE } from 'react-admin';
import configuredProviders from '../System/configuredProviders';
import StoredUser from '../System/StoredUser';
import updateTve from './updateTve';
import { ID_TRANSFORM } from '../System/configuredProvider';
import updateSchedule from './updateSchedule';
import { API_URL } from './ReactAppConfig';

const storedUser = new StoredUser();

const withAuth = (config) => {
  const { token } = storedUser.load();

  if (!Object.prototype.hasOwnProperty.call(config, 'headers')) config.headers = {};

  config.headers.Authorization = `Bearer ${token}`;

  return config;
};

const config = {
  defaults: {
    fetchClient: (config) => axios(withAuth(config)),
    apiUrl: API_URL,
  },
  resources: {
    canesversions: {
      transform: ID_TRANSFORM('canesVersionId'),
    },

    canesversionsactivesonly: {
      transform: ID_TRANSFORM('canesVersionId'),
    },

    classstatuses: {
      endpoint: 'lookupcodes',
      queryParams: () => ({ lookup_type: 'class status' }),
      transform: ID_TRANSFORM('lookupCodeId'),
    },

    tvestatuses: {
      endpoint: 'lookupcodes',
      queryParams: () => ({ lookup_type: 'tve status' }),
      transform: ID_TRANSFORM('lookupCodeId'),
    },

    usertrainingstatuses: {
      endpoint: 'lookupcodes',
      queryParams: () => ({ lookup_type: 'app user training status' }),
      transform: ID_TRANSFORM('lookupCodeId'),
    },

    registrationstatuses: {
      endpoint: 'lookupcodes',
      queryParams: () => ({ lookup_type: 'User Registration Status' }),
      transform: ID_TRANSFORM('lookupCodeId'),
    },

    userstatuses: {
      endpoint: 'lookupcodes',
      queryParams: () => ({ lookup_type: 'User Status' }),
      transform: ID_TRANSFORM('lookupCodeId'),
    },

    someclassstatuses: {
      transform: ID_TRANSFORM('lookupCodeId'),
    },

    organizations: {
      endpoint: 'organizations',
      transform: ID_TRANSFORM('id'),
    },

    tracks: {
      endpoint: 'tracks',
      transform: ID_TRANSFORM('id'),
      queryParams: ({ filter }) => {
        if (!filter) return {};

        const { userid } = filter;

        return {
          userid
        };
      },
    },

    usertracks: {
      endpoint: 'usertracks',
      transform: ID_TRANSFORM('id'),
      queryParams: ({ filter }) => {
        if (!filter) return {};

        const { userid } = filter;

        return {
          userid
        };
      },
    },

    administration: {
      transform: (item) => {
        // if the time portion is not split out, the Class page may show start and end dates as 1 day earlier:
        if (item.tveClass.startDate)
          item.tveClass.startDate = item.tveClass.startDate.split('T')[0];
        if (item.tveClass.endDate) item.tveClass.endDate = item.tveClass.endDate.split('T')[0];
        item.id = item['tveClassAppUserId'];
        if (item.tvePauseTime) {
          item.tvePauseTime = convertMilitaryToStandard(item.tvePauseTime);
          if (item.tveClass.tveTimeZone) {
            item.tvePauseTime = item.tvePauseTime + ' ' + item.tveClass.tveTimeZone.abbreviation;
          }
        }
        if (item.tveStartupTime) {
          item.tveStartupTime = convertMilitaryToStandard(item.tveStartupTime);
          if (item.tveClass.tveTimeZone) {
            item.tveStartupTime =
              item.tveStartupTime + ' ' + item.tveClass.tveTimeZone.abbreviation;
          }
        }
        return item;
      },
      providerDecorator: (defaultProvider) => (type, params, config) => {
        const { id, data } = params;

        if (type === UPDATE && 'tve' in data && 'action' in data.tve) {
          return updateTve(id, data.tve, config);
        }

        return defaultProvider(type, params, config);
      },
      untransform: (item) => {
        if (item.tvePauseTime) {
          let index = item.tvePauseTime.lastIndexOf(' ');
          item.tvePauseTime = item.tvePauseTime.substring(0, index);
          item.tvePauseTime = convertStandardToMilitary(item.tvePauseTime);
          let inputDate = new Date();
          let timeArray = item.tvePauseTime.split(':');
          inputDate.setHours(parseInt(timeArray[0]));
          inputDate.setMinutes(parseInt(timeArray[1]));
          inputDate.setSeconds(0);
          item.tvePauseTime = inputDate;
        }
        if (item.tveStartupTime) {
          let index = item.tveStartupTime.lastIndexOf(' ');
          item.tveStartupTime = item.tveStartupTime.substring(0, index);
          item.tveStartupTime = convertStandardToMilitary(item.tveStartupTime);
          let inputDate = new Date();
          let timeArray = item.tveStartupTime.split(':');
          inputDate.setHours(parseInt(timeArray[0]));
          inputDate.setMinutes(parseInt(timeArray[1]));
          inputDate.setSeconds(0);
          item.tveStartupTime = inputDate;
        }
        return item;
      },
    },

    courses: {
      endpoint: 'courses',
      transform: ID_TRANSFORM('courseId'),
    },

    tveclasses: {
      transform: (item) => {
        item.id = item.tveClassId;
        if (item.course) item.courseId = item.course.courseId;
        // remove time portion of start & end dates (e.g., 2019-08-01T07:00:00.000+00:00 becomes 2019-08-01)
        if (item.startDate) item.startDate = item.startDate.split('T')[0];
        if (item.endDate) item.endDate = item.endDate.split('T')[0];
        if (item.startTime) {
          let lastIndex = item.startTime.lastIndexOf(':');
          item.startTime = item.startTime.substring(0, lastIndex);
        }
        if (item.startupActive && item.startupActive === 'Y') {
          item.startupTime = item.startTime;
          item.startupTime = convertMilitaryToStandard(item.startupTime);
          if (item.tveTimeZone) {
            item.startupTime = item.startupTime + ' ' + item.tveTimeZone.abbreviation;
          }
        }
        if (item.endTime) {
          let lastIndex = item.endTime.lastIndexOf(':');
          item.endTime = item.endTime.substring(0, lastIndex);
        }
        if (item.pauseActive && item.pauseActive === 'Y') {
          item.pauseTime = item.endTime;
          item.pauseTime = convertMilitaryToStandard(item.pauseTime);
          if (item.tveTimeZone) {
            item.pauseTime = item.pauseTime + ' ' + item.tveTimeZone.abbreviation;
          }
        }
        return item;
      },
      untransform: (item) => {
        if (item.startDate && item.startTime) {
          item.startTime = item.startDate.split('T')[0] + 'T' + item.startTime + ':00';
        }
        if (item.endDate && item.endTime) {
          item.endTime = item.endDate.split('T')[0] + 'T' + item.endTime + ':00';
        }
        return item;
      },
      providerDecorator: (defaultProvider) => (type, params, config) => {
        const { id, data } = params;

        if (type === UPDATE && 'schedule' in data && 'action' in data.schedule) {
          return updateSchedule(id, data.schedule, config);
        }

        return defaultProvider(type, params, config);
      },
    },

    traininglocations: {
      endpoint: 'traininglocations',
      transform: ID_TRANSFORM('id'),
    },

    timezones: {
      endpoint: 'timezones',
      transform: ID_TRANSFORM('id'),
      queryParams: ({ filter }) => {
        if (!filter) return {};

        const { q } = filter;

        return {
          official: q,
        };
      },
    },

    tveclassappusers: {
      transform: (item) => {
        item.id = item['tveClassAppUserId'];
        if (item.tvePauseTime) {
          item.tvePauseTime = convertMilitaryToStandard(item.tvePauseTime);
          if (item.tveClass.tveTimeZone) {
            item.tvePauseTime = item.tvePauseTime + ' ' + item.tveClass.tveTimeZone.abbreviation;
          }
        }
        if (item.tveStartupTime) {
          item.tveStartupTime = convertMilitaryToStandard(item.tveStartupTime);
          if (item.tveClass.tveTimeZone) {
            item.tveStartupTime =
              item.tveStartupTime + ' ' + item.tveClass.tveTimeZone.abbreviation;
          }
        }
        return item;
      },
      providerDecorator: (defaultProvider) => (type, params, config) => {
        const { id, data } = params;

        if (type === UPDATE && 'tve' in data && 'action' in data.tve) {
          return updateTve(id, data.tve, config);
        }

        return defaultProvider(type, params, config);
      },
      untransform: (item) => {
        if (item.tveClass.startTime) {
          let inputDate = new Date();
          let timeArray = item.tveClass.startTime.split(':');
          inputDate.setHours(parseInt(timeArray[0]));
          inputDate.setMinutes(parseInt(timeArray[1]));
          inputDate.setSeconds(0);
          item.tveClass.startTime = inputDate;
        }
        if (item.tveClass.endTime) {
          let inputDate = new Date();
          let timeArray = item.tveClass.endTime.split(':');
          inputDate.setHours(parseInt(timeArray[0]));
          inputDate.setMinutes(parseInt(timeArray[1]));
          inputDate.setSeconds(0);
          item.tveClass.endTime = inputDate;
        }
        if (item.tvePauseTime) {
          let index = item.tvePauseTime.lastIndexOf(' ');
          item.tvePauseTime = item.tvePauseTime.substring(0, index);
          item.tvePauseTime = convertStandardToMilitary(item.tvePauseTime);
          let inputDate = new Date();
          let timeArray = item.tvePauseTime.split(':');
          inputDate.setHours(parseInt(timeArray[0]));
          inputDate.setMinutes(parseInt(timeArray[1]));
          inputDate.setSeconds(0);
          item.tvePauseTime = inputDate;
        }
        if (item.tveStartupTime) {
          let index = item.tveStartupTime.lastIndexOf(' ');
          item.tveStartupTime = item.tveStartupTime.substring(0, index);
          item.tveStartupTime = convertStandardToMilitary(item.tveStartupTime);
          let inputDate = new Date();
          let timeArray = item.tveStartupTime.split(':');
          inputDate.setHours(parseInt(timeArray[0]));
          inputDate.setMinutes(parseInt(timeArray[1]));
          inputDate.setSeconds(0);
          item.tveStartupTime = inputDate;
        }
        return item;
      },
    },

    inactivestudents: {
      queryParams: ({ filter }) => {
        if (!filter) return {};

        const { q } = filter;

        return {
          username: q,
        };
      },
    },

    myenvironments: {
      transform: ID_TRANSFORM('tveClassAppUserId'),
    },

    tveclassappusersbyclass: {
      transform: ID_TRANSFORM('tveClassAppUserId'),
      queryParams: ({ filter }) => {
        if (!filter) return {};

        const { tveclassid } = filter;

        return {
          tveclassid
        };
      },
    },

    users: {
      transform: (user) => {
        user.hasEdipiHash = !!user.edipiHash;
        user.hasEdipiHashYesNo = user.hasEdipiHash ? 'Yes' : 'No';

        return user;
      },
      queryParams: ({ filter }) => {
        if (!filter) return {};

        const { username, role, track, organization, userStatus, approvedBy } = filter;

        return {
          username,
          role,
          track,
          organization,
          userStatus,
          approvedBy,
        };
      },
    },

    usersactive: {
      transform: (user) => {
        user.hasEdipiHash = !!user.edipiHash;
        user.hasEdipiHashYesNo = user.hasEdipiHash ? 'Yes' : 'No';

        return user;
      },
      queryParams: ({ filter }) => {
        if (!filter) return {};

        const { q, role } = filter;

        return {
          username: q,
          role,
        };
      },
    },

    appusers: {
      transform: ID_TRANSFORM('appUserId'),
    },

    tveapppropvalues: {
      endpoint: 'tveapppropvalues',
      transform: ID_TRANSFORM('tveAppPropertyValueId'),
    },

    application_config_properties: {
      endpoint: 'tveappprops',
      transform: ID_TRANSFORM('tveAppPropertyId'),
    },

    feedback: {
      transform: ID_TRANSFORM('feedbackId'),
    },

    instructorresourceaccesses: {
      transform: ID_TRANSFORM('id'),
    },
  },
};

const convertMilitaryToStandard = (time) => {
  time = time.split(':'); // convert to array
  // fetch
  const hours = Number(time[0]);
  const minutes = Number(time[1]);

  // calculate
  let timeValue = null;
  if (hours > 0 && hours <= 12) {
    timeValue = '' + hours;
  } else if (hours > 12) {
    timeValue = '' + (hours - 12);
  } else if (hours === 0) {
    timeValue = '12';
  }

  timeValue += minutes < 10 ? ':0' + minutes : ':' + minutes; // get minutes
  timeValue += hours >= 12 ? ' PM' : ' AM'; // get AM/PM
  return timeValue;
};

const convertStandardToMilitary = (time) => {
  const PM = time.match('PM') ? true : false;

  time = time.split(':');
  const min = time[1];
  let hour = null;

  if (PM) {
    hour = 12 + parseInt(time[0], 10);
  } else {
    hour = time[0];
  }

  return hour + ':' + min + ':00';
};

export default configuredProviders(config);
export { withAuth };
