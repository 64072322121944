import * as React from 'react';
import { createElement } from 'react';
import {
  Menu,
  useResourceDefinitions,
  usePermissions,
  useLogout,
  DashboardMenuItem,
} from 'react-admin';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SourceIcon from '@mui/icons-material/Source';
import LabelIcon from '@mui/icons-material/Label';
import navylogo from '../img/sealNavy.webp';
import { Navigate } from 'react-router-dom';

export const TveMenu = () => {
  const resources = useResourceDefinitions();

  const { permissions } = usePermissions();

  // get auth cookie if it exists
  const authCookieValue = document.cookie
    .split(';')
    .map((cookie) => cookie.trim().split('='))
    .find(([name]) => name === 'auth');

  // without redirecting back to login page if there's no local auth cookie, a user was able to get in to TVE again without logging in
  if (!authCookieValue) {
    return <Navigate replace to="/login" />;
  }

  return (
    // render the menu of resources
    <Menu>
      {Object.keys(resources)
        .filter(
          (name) =>
            name === 'inactivestudents' ||
            name === 'tveclasses' ||
            name === 'courses' ||
            name === 'traininglocations' ||
            name === 'classstatuses' ||
            name === 'tvestatuses' ||
            name === 'usertrainingstatuses' ||
            name === 'canesversions' ||
            name === 'tracks' ||
            name === 'usertracks'
        )
        .map((name) => {
          // seems hacky, maybe refactor:
          //  exclude feedback resource from menu for instrs, but allow them to create it (FeedbackCreate)
          if (name === 'feedback' && permissions === 'INSTRUCTOR') {
            return null;
          }

          return (
            <Menu.Item
              key={name}
              to={`/${name}`}
              primaryText={(resources[name].options && resources[name].options.label) || name}
              leftIcon={createElement(resources[name].icon)}
            />
          );
        })}

      {/* show Administration and Reports page in sidebar for ADMIN roles */}
      {permissions === 'ADMIN' && (
        <Menu.Item
          to="/administrationandreports"
          primaryText="Administration/Reports"
          leftIcon={<ContactSupportIcon />}
        />
      )}

      {Object.keys(resources)
        .filter((name) => name === 'users' || name === 'tveapppropvalues' || name === 'feedback')
        .map((name) => {
          // seems hacky, maybe refactor:
          //  exclude feedback resource from menu for instrs, but allow them to create it (FeedbackCreate)
          if (name === 'feedback' && permissions === 'INSTRUCTOR') {
            return null;
          }

          return (
            <Menu.Item
              key={name}
              to={`/${name}`}
              primaryText={(resources[name].options && resources[name].options.label) || name}
              leftIcon={createElement(resources[name].icon)}
            />
          );
        })}

      {permissions === 'STUDENT' && (
        <Menu.Item to="/" primaryText="Student Dashboard" leftIcon={<LabelIcon />} />
      )}
      {/* show Resources in sidebar for all roles except STUDENT */}
      {permissions !== 'STUDENT' && (
        <Menu.Item to="/resources" primaryText="Resources" leftIcon={<SourceIcon />} />
      )}
      {/* show Help and Support page in sidebar for all roles except STUDENT */}
      {permissions !== 'STUDENT' && (
        <Menu.Item to="/help" primaryText="Help and Support" leftIcon={<ContactSupportIcon />} />
      )}
      <img src={navylogo} alt="navy logo" style={{ width: '160px', marginLeft: '20px' }} />
    </Menu>
  );
};
