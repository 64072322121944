import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import BackButton from '../Components/BackButton';
import { Title, Button, useGetList, usePermissions } from 'react-admin';
import TveTitle from '../Components/TveTitle';
import { CANES_TVE_ENV } from '../Config/ReactAppConfig';
import StoredUser from '../System/StoredUser';
import { CircularProgress } from '@mui/material';

const ResourceLink = ({ label, url }) => (
  <span style={{ fontSize: '14px' }}>
    <br />
    {label}&nbsp;
    <Button
      sx={{
        fontFamily: 'Segoe UI',
        fontSize: '14px',
        pb: 0,
        pt: 0,
        pr: 0,
        textTransform: 'none',
        justifyContent: 'center',
      }}
      variant="contained"
      onClick={() => window.open(url, '_blank')}
    >
      here
    </Button>
  </span>
);

const ResourcesField = () => {
  const appUserId = new StoredUser().load().appUser.appUserId;

  const { data: userData, isLoading: isLoadingUsers, error: errorUsers } = useGetList('users', {
    pagination: { page: 1, perPage: 4000 },
  });

  const userId = userData ? userData.find(user => user.appUser?.appUserId === appUserId)?.id : null;

  const { data: tracksData, isLoading, error } = useGetList('tracks', {
    filter: { userid: userId },
    sort: { field: 'name', order: 'ASC' }
  });

  if (isLoading) return <CircularProgress size="1rem" />;
  if (error) return <span>error</span>;
  if (!tracksData || tracksData.length === 0) return <span></span>;

  const hasCANES = tracksData.some(track => track.name === "CANES");
  const hasNSVT = tracksData.some(track => track.name === "NSVT");

  return (
    <div>
      <Card>
        <CardHeader />
        {hasCANES && (
          <CardContent>
            <h3>CANES Resources</h3>
            <ResourceLink label="Access Instructor Resources" url="/instructor-content/" />
            <ResourceLink label="Access Student Resources" url="/student-content/" />
          </CardContent>
        )}
        {hasNSVT && (
          <CardContent>
            <h3>NSVT Resources</h3>
            <ResourceLink label="Access NSVT Instructor Resources" url="/nsvt-content/" />
            <ResourceLink label="Access NSVT Student Resources" url="/student-nsvt-content/" />
          </CardContent>
        )}
      </Card>
    </div>
  );
};

const Resources = () => {
  const { permissions } = usePermissions();
  return (
    <>
      <Title title={<TveTitle pageName="Resources" />} />
      <BackButton label="Return to Class List" to="/tveclasses" />
      <hr />
      {permissions === 'ADMIN' && (
        <Card>
          <CardHeader title="Resources" />
          <CardContent>
            <h3>CANES</h3>
            <ResourceLink label="Access TVE Instructor Resources" url="/instructor-content/" />
            <ResourceLink label="Access TVE Student Resources" url="/student-content/" />
          </CardContent>
          <CardContent>
            <h3>NSVT</h3>
            <ResourceLink label="Access NSVT Instructor Resources" url="/nsvt-content/" />
            <ResourceLink label="Access NSVT Student Resources" url="/student-nsvt-content/" />
          </CardContent>
        </Card>
      )}
      {permissions === 'INSTRUCTOR' && (
        <ResourcesField />
      )}
    </>
  );
};
export default Resources;
