import React, { useEffect } from 'react';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  ReferenceField,
  TextField,
  TextInput,
  DateInput,
  SelectInput,
  Labeled,
  useGetList,
  RadioButtonGroupInput,
  useNotify,
  useRedirect,
  usePermissions,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import TveTitle from '../Components/TveTitle';
import { INSTRUCTOR_TRACKS } from '../Config/AuthProvider';
import { useFormContext } from 'react-hook-form';
import { withAuth } from '../Config/ApplicationDataProvider';
import axios from 'axios';
import { API_URL } from '../Config/ReactAppConfig';
import StoredUser from '../System/StoredUser';
import { CircularProgress } from '@mui/material';

const EndDateMessage = () => (
  <span
    style={{ color: 'red', backgroundColor: 'white', textShadow: '4px 4px 8px rgba(0, 0, 0, 0.8)' }}
  >
    (This date can be modified/extended even if the class status is showing "Complete" or
    "Cancelled")
  </span>
);

const StartTimeMessage = () => (
  <span
    style={{ color: 'red', backgroundColor: 'white', textShadow: '4px 4px 8px rgba(0, 0, 0, 0.8)' }}
  >
    (All stacks in this class will automatically start everyday at this time if the class Auto-Start
    option is enabled)
  </span>
);

const EndTimeMessage = () => (
  <span
    style={{ color: 'red', backgroundColor: 'white', textShadow: '4px 4px 8px rgba(0, 0, 0, 0.8)' }}
  >
    (All stacks in this class will automatically pause everyday at this time. The class Auto-Pause
    option is enabled by default)
  </span>
);

const validateNameUnique = async (enteredName) => {
  const response = await axios(withAuth({ url: `${API_URL}/tveclass/${enteredName}` }));
  return response.data?.length > 0 ? 'Class Name already exists' : undefined;
};

const validateClassCreation = async (values) => {
  const errors = {};
  const invalidChars = ['/', '\\', '%', '?', '|', '[', ']', '#'];
  if (!values.name) {
    errors.name = 'Class Name is required';
  } else if (invalidChars.some((char) => values.name.includes(char))) {
    errors.name = 'Class Name contains an invalid character: /, \\, %, ?, |, [, ], or #';
  } else if (values.name?.length > 255) {
    errors.name = 'Class Name must be not be > 255 characters';
  } else {
    const nameError = await validateNameUnique(values.name);
    if (nameError) {
      errors.name = nameError;
    }
  }
  // if (!values.track) {
  //   errors.track = 'Track is required';
  // }
  if (!values.classType) {
    errors.classType = 'Class Type is required';
  }
  if (!values.tveTrainingLocation) {
    errors.tveTrainingLocation = 'Training Location is required';
  }
  if (!values.tveTimeZone) {
    errors.tveTimeZone = 'Training Location Time Zone is required';
  }
  if (!values.startDate) {
    errors.startDate = 'Start Date is required';
  }
  if (!values.endDate) {
    errors.endDate = 'End Date is required';
  }
  if (values.endDate <= values.startDate) {
    errors.endDate = 'End Date must be after Start Date';
  } else {
    let today = new Date().toISOString().split('T')[0];
    if (values.endDate < today) {
      errors.endDate = 'End Date must not be earlier than today';
    }
  }
  if (!values.startTime) {
    errors.startTime = 'Daily Start Time is required';
  }
  if (!values.endTime) {
    errors.endTime = 'Daily End Time is required';
  }
  if (values.endTime <= values.startTime) {
    errors.endTime = 'End Time must be after Start Time';
  } else if (values.endTime > values.startTime) {
    const endTime = values.endTime.split(':');
    const endHourMinute = parseInt(endTime[0] + endTime[1]);
    const startTime = values.startTime.split(':');
    const startHourMinute = parseInt(startTime[0] + startTime[1]);
    const diff = endHourMinute - startHourMinute;
    if (diff > 800)
      errors.endTime = 'Difference between End Time and Start Time cannot be greater than 8 hours';
  }
  if (!values.course) {
    errors.course = 'Course is required';
  }
  if (!values.tveClassStatus) {
    errors.tveClassStatus = 'Class Status is required';
  } else if (values.tveClassStatus != 1) {
    errors.tveClassStatus = 'Class Status must be Not Started';
  }
  return errors;
};

const validateClassCreationWithTrack = async (values) => {
  const errors = {};
  const invalidChars = ['/', '\\', '%', '?', '|', '[', ']', '#'];
  if (!values.name) {
    errors.name = 'Class Name is required';
  } else if (invalidChars.some((char) => values.name.includes(char))) {
    errors.name = 'Class Name contains an invalid character: /, \\, %, ?, |, [, ], or #';
  } else if (values.name?.length > 255) {
    errors.name = 'Class Name must be not be > 255 characters';
  } else {
    const nameError = await validateNameUnique(values.name);
    if (nameError) {
      errors.name = nameError;
    }
  }
  if (!values.track) {
    errors.track = 'Track is required';
  }
  if (!values.classType) {
    errors.classType = 'Class Type is required';
  }
  if (!values.tveTrainingLocation) {
    errors.tveTrainingLocation = 'Training Location is required';
  }
  if (!values.tveTimeZone) {
    errors.tveTimeZone = 'Training Location Time Zone is required';
  }
  if (!values.startDate) {
    errors.startDate = 'Start Date is required';
  }
  if (!values.endDate) {
    errors.endDate = 'End Date is required';
  }
  if (values.endDate <= values.startDate) {
    errors.endDate = 'End Date must be after Start Date';
  } else {
    let today = new Date().toISOString().split('T')[0];
    if (values.endDate < today) {
      errors.endDate = 'End Date must not be earlier than today';
    }
  }
  if (!values.startTime) {
    errors.startTime = 'Daily Start Time is required';
  }
  if (!values.endTime) {
    errors.endTime = 'Daily End Time is required';
  }
  if (values.endTime <= values.startTime) {
    errors.endTime = 'End Time must be after Start Time';
  } else if (values.endTime > values.startTime) {
    const endTime = values.endTime.split(':');
    const endHourMinute = parseInt(endTime[0] + endTime[1]);
    const startTime = values.startTime.split(':');
    const startHourMinute = parseInt(startTime[0] + startTime[1]);
    const diff = endHourMinute - startHourMinute;
    if (diff > 800)
      errors.endTime = 'Difference between End Time and Start Time cannot be greater than 8 hours';
  }
  if (!values.course) {
    errors.course = 'Course is required';
  }
  if (!values.tveClassStatus) {
    errors.tveClassStatus = 'Class Status is required';
  } else if (values.tveClassStatus != 1) {
    errors.tveClassStatus = 'Class Status must be Not Started';
  }
  return errors;
};

const getValidationFunction = (permissions) => {
  return permissions === 'ADMIN' ? validateClassCreationWithTrack : validateClassCreation;
};

const formDefaultValues = () => ({ tveClassStatus: 1 });

const TimeZoneInput = () => {
  const formContext = useFormContext();

  const { data: locationdata } = useGetList('traininglocations');

  useEffect(() => {
    const values = formContext.getValues();
    const { dirtyFields } = formContext.formState;

    // if the user set the location, but did not set the time zone (to intentionally override the default)
    //  then set the time zone to the default
    if (
      Object.keys(dirtyFields).includes('tveTrainingLocation') &&
      !Object.keys(dirtyFields).includes('tveTimeZone')
    ) {
      var selectedlocation = locationdata.find((loc) => {
        return loc.id === values.tveTrainingLocation;
      });
      formContext.setValue('tveTimeZone', selectedlocation.tveTimeZone.id);
    }
  });

  return (
    <ReferenceInput
      source="tveTimeZone"
      reference="timezones"
      sort={{ field: 'sort', order: 'ASC' }}
    >
      <SelectInput optionText="official" label="Training Location Time Zone" sx={{ width: 380 }} />
    </ReferenceInput>
  );
};

// get tracks for the the user
// if the user has only one track, show it here (and in the TveClassController, create the class for that track)
// if the user has more than one track, show the list here and allow the user to choose
const Track = () => {
  const appUserId = new StoredUser().load().appUser.appUserId;

  const { data: userData, isLoading: isLoadingUsers, error: errorUsers } = useGetList('users', {
    pagination: { page: 1, perPage: 4000 },
  });

  const userId = userData ? userData.find(user => user.appUser?.appUserId === appUserId)?.id : null;

  const { data: tracksData, isLoading: isLoadingTracks, error: errorTracks } = useGetList('tracks', {
    filter: { userid: userId },
    sort: { field: 'name', order: 'ASC' },
  });

  if (isLoadingUsers || isLoadingTracks) return <CircularProgress />;
  if (errorUsers || errorTracks) return <div>Error loading data</div>;

  if (tracksData && tracksData.length === 1) {
    return <span>{tracksData[0].name} (instructor's track)</span>;
  }

  if (tracksData && tracksData.length > 1) {
    return (
      <SelectInput
        label="Track"
        source="track"
        choices={INSTRUCTOR_TRACKS}
        optionValue="name"
      />
    );
  }

  return null; // handle case where tracksData is empty or undefined
};

const TveClassCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { permissions } = usePermissions();

  const onSuccess = (data) => {
    notify(`Class created`);
    redirect('/list');
  };

  return (
    <>
      <BackButton label="Return to Classes List" to="/tveclasses" />
      <Create title={<TveTitle pageName="Create Class" />} mutationOptions={{ onSuccess }}>
        <SimpleForm
          defaultValues={formDefaultValues()}
          mode="onBlur"
          reValidateMode="onBlur"
          validate={getValidationFunction(permissions)} // Dynamic validation function
        >
          <TextInput source="name" label="Class Name" sx={{ width: 700 }} />
          {/* allow ADMINs to choose which track to assign the class */}
          {permissions === 'ADMIN' && (
            <SelectInput
              label="Track"
              source="track"
              choices={INSTRUCTOR_TRACKS}
              optionValue="name"
            />
          )}
          {/* for INSTRUCTORs, */}
          {/* ... if they have only one track, they can only create a class of that track */}
          {/* ... if they have more than one track, they can choose */}
          {permissions === 'INSTRUCTOR' && (
            <Labeled label="Track" style={{ marginBottom: '10px' }}>
              <Track />
            </Labeled>
          )}
          <RadioButtonGroupInput
            source="classType"
            label="Class Type"
            choices={[
              {
                id: 'I',
                name:
                  "Instructing: Only use for scheduled Sailors' training " + '\u00A0 '.repeat(90),
              },
              {
                id: 'N',
                name: 'Non-Instructing: Not for classroom instruction. Use for Practice, Test, Demo, Review and Explore C4I TVE features',
              },
            ]}
            defaultValue="I"
          />
          <ReferenceInput
            source="tveTrainingLocation"
            reference="traininglocations"
            sort={{ field: 'sort', order: 'ASC' }}
          >
            <SelectInput
              optionText={(choice) => `${choice.displayName}`}
              label="Training Location"
              sx={{ width: 380 }}
            />
          </ReferenceInput>
          <TimeZoneInput />
          <DateInput sx={{ width: 400 }} source="startDate" />
          <div style={{ display: 'flex' }}>
            <DateInput sx={{ width: 400 }} source="endDate" />
            <div style={{ marginTop: '20px', paddingLeft: '10px' }}>
              <EndDateMessage />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <TextInput
              sx={{ width: 200 }}
              defaultValue="08:00"
              label="Daily Start Time (type or select)"
              source="startTime"
              type="time"
            />
            <div style={{ marginTop: '20px', paddingLeft: '10px' }}>
              <StartTimeMessage />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <TextInput
              sx={{ width: 200 }}
              defaultValue="16:00"
              label="Daily End Time (type or select)"
              source="endTime"
              type="time"
            />
            <div style={{ marginTop: '20px', paddingLeft: '10px' }}>
              <EndTimeMessage />
            </div>
          </div>
          <ReferenceInput
            source="course"
            reference="courses"
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput label="Course" optionText={(choice) => `${choice.name} - ${choice.description}`} sx={{ width: 800 }} />
          </ReferenceInput>
          <ReferenceInput
            source="tveClassStatus"
            reference="classstatuses"
            sort={{ field: 'id', order: 'ASC' }}
          >
            <SelectInput label="Status" optionText="name" sx={{ width: 300, pb: 0 }} />
          </ReferenceInput>
          <ReferenceField source="tveClassStatus" reference="classstatuses">
            <Labeled label="Status">
              <TextField label="Status" source="name" />
            </Labeled>
          </ReferenceField>
        </SimpleForm>
      </Create>
    </>
  );
};

export default TveClassCreate;
