import React from 'react';
import { List, TextField, DatagridConfigurable, TopToolbar, BulkDeleteButton, ChipField, SelectColumnsButton } from 'react-admin';
import TveTitle from '../Components/TveTitle';
import { Container, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const AdministrationListActions = () => {
  return (
    <TopToolbar>
      <SelectColumnsButton variant="contained" />
    </TopToolbar>
  );
};

const Wrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '365px auto',
  gridGap: '10px 2em',
});

const Empty = () => (
  <Container>
    <Wrapper>
      <Box textAlign="left" m={1}>
        <Typography variant="h6">No user tracks found</Typography>
      </Box>
    </Wrapper>
  </Container>
);

const BulkActionButtons = () => (
  <>
    <BulkDeleteButton
      sx={{
        color: 'white',
        backgroundColor: 'red',
        padding: '6px 11px',
        '&:hover': {
          backgroundColor: '#0066CC',
        },
      }}
    />
  </>
);

const UserTrackList = (props) => {
  return (<List
    title={<TveTitle pageName="User Tracks" />}
    // props needed to get the resource prop
    {...props}
    perPage={25}
    actions={<AdministrationListActions />}
    empty={<Empty />}
  >
    <DatagridConfigurable
      bulkActionButtons={<BulkActionButtons />}
      omit={['id', 'user.id', 'track.id']}>
      <TextField label="User Track ID" source="id" />
      <TextField label="User ID" source="user.id" />
      <TextField label="User Name" source="user.username" />
      <TextField label="Track Id" source="track.id" />
      <ChipField label="Track Name" source="track.name" />
      <TextField label="Track Description" source="track.description" />
    </DatagridConfigurable>
  </List>
  )
};
export default UserTrackList;
