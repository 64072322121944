import React from 'react';
import { InfiniteList, TextField, Datagrid, CreateButton, TopToolbar } from 'react-admin';
import TveTitle from '../Components/TveTitle';

const TrackList = () => (
  <InfiniteList
    title={<TveTitle pageName="Tracks" />}
    perPage={25}
    exporter={false}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField label="Track" source="name" />
      <TextField source="description" />
    </Datagrid>
  </InfiniteList>
);
export default TrackList;
