import React, { useState, useEffect } from 'react';
import {
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  TextField,
  TextInput,
  ReferenceInput,
  SelectInput,
  Labeled,
  required,
  useRecordContext,
  FormDataConsumer,
  email,
  number,
  useGetList,
  useDataProvider,
  useNotify
} from 'react-admin';
import { ROLES } from '../Config/AuthProvider';
import BackButton from '../Components/BackButton';
import { INSTRUCTOR_TRACKS } from '../Config/AuthProvider';
import { useFormContext } from 'react-hook-form';
import { Chip, CircularProgress, Autocomplete, IconButton, TextField as MuiTextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const UsersEditTitle = () => {
  const record = useRecordContext();
  return <span>Edit {record ? ` User: ${record.username}` : ''}</span>;
};

const CustomToolbar = () => {
  const record = useRecordContext();
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmTitle={`Delete Student: ${record.username}`}
        confirmContent={`Are you sure you want to delete ${record.username}?`}
        variant="contained"
        sx={{
          color: 'white',
          backgroundColor: 'red',
          padding: '6px 11px',
          '&:hover': {
            backgroundColor: '#0066CC',
          },
        }}
      />
    </Toolbar>
  );
};

const isRoleStudent = (role) => {
  return role === 'STUDENT' ? true : false;
};

// regex to validate email format
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// regex to validate (123) 456-7890 format (not needed; auto-formatting instead)
const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

// format phone number with parentheses and dash
const formatPhoneNumber = (value) => {
  if (!value) return '';
  const numericValue = value.replace(/\D/g, '');
  if (numericValue.length <= 3) {
    return `(${numericValue}`;
  } else if (numericValue.length <= 6) {
    return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
  } else {
    return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
  }
};

// custom phone number input
const PhoneNumberInput = () => {
  const formContext = useFormContext();

  const handlePhoneNumberChange = (event) => {
    const formattedValue = formatPhoneNumber(event.target.value);
    formContext.setValue('phoneNumber', formattedValue);
  };

  return (
    <TextInput
      label="Phone Number"
      source="phoneNumber"
      validate={required()}
      onChange={handlePhoneNumberChange}
    />
  );
};

const validateUsersEdit = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Username is required';
  }
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Must be a valid email';
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Phone Number is required';
  } else if (values.phoneNumber?.length < 14) {
    errors.phoneNumber = 'Phone Number contains too few digits';
  }
  return errors;
};


const TracksField = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [tracks, setTracks] = useState([]);
  const [allTracks, setAllTracks] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const notify = useNotify();

  // fetch existing tracks for the user
  const { data, isLoading, error } = useGetList('tracks', {
    filter: { userid: record.id },
    sort: { field: 'name', order: 'ASC' }
  });

  // fetch all available tracks for selection
  useEffect(() => {
    dataProvider.getList('tracks', { pagination: { page: 1, perPage: 100 }, sort: { field: 'name', order: 'ASC' } })
      .then(({ data }) => setAllTracks(data))
      .catch(() => setAllTracks([]));
  }, [dataProvider]);

  useEffect(() => {
    if (data) {
      setTracks(data);
    }
  }, [data]);

  const handleAddUserTrack = async () => {
    if (selectedTrack) {
      const newUserTrack = {
        user: { id: record.id },
        track: { id: selectedTrack.id, name: selectedTrack.name }
      }

      try {
        const response = await dataProvider.create('usertracks', { data: newUserTrack });
        setTracks([...tracks, { id: response.data.id, ...newUserTrack.track }]);
        setSelectedTrack(null);
        notify(`${selectedTrack.name} track added successfully to this user`, { type: 'success' });
      } catch (error) {
        console.error('Error adding track:', error);
      }
    }
  };

  const handleRemoveUserTrack = async (userid, trackId) => {
    try {

      if (tracks.length === 1) {
        notify("Instructor must have at least one track", { type: "warning" });
        return;
      }

      const trackToRemove = tracks.find(track => track.id === trackId);

      if (!trackToRemove) {
        console.warn('Track not found');
        return;
      }

      // fetch the usertrack entry
      const { data } = await dataProvider.getList('usertracks', {
        filter: { 'user.id': userid, 'track.id': trackId }
      });

      // find exact usertrack entry corresponding to this user and track
      const userTrackEntry = data.find(entry => entry.track.id === trackId);

      if (!userTrackEntry) {
        console.warn('No matching usertrack entry found.');
        return;
      }

      // delete the usertrack entry
      await dataProvider.delete('usertracks', { id: userTrackEntry.id });

      // update local state: remove only the correct track
      setTracks(tracks.filter(track => track.id !== trackId));

      notify(`${trackToRemove.name} track removed successfully from this user`, { type: 'success' });

    } catch (error) {
      console.error('Error removing track:', error);
      notify('Error removing track', { type: 'error' });
    }

  };


  if (isLoading) return <CircularProgress />;
  if (error) return <span>Error loading tracks</span>;

  return (
    <Box>
      {/* display existing tracks with remove option */}
      <div style={{ display: 'flex' }}>
        {tracks.map(track => (
          <Chip
            key={track.id}
            label={track.name}
            sx={{ backgroundColor: '#D3D3D3' }}
            onDelete={() => handleRemoveUserTrack(record.id, track.id)}
            deleteIcon={<DeleteIcon />}
          />
        ))}

        {/* dropdown for adding new tracks */}
        <div style={{ paddingLeft: '220px', paddingTop: '1px' }}>
          <Autocomplete
            options={allTracks.filter(track => !tracks.some(t => t.id === track.id))}
            getOptionLabel={(option) => option.name}
            value={selectedTrack}
            onChange={(_, newValue) => setSelectedTrack(newValue)}
            renderInput={(params) => <MuiTextField {...params} label="Add a Track" variant="outlined" />}
            sx={{ width: 200 }}
          />
        </div>
        <div>
          <IconButton onClick={handleAddUserTrack} color="primary" sx={{ ml: 1, paddingTop: '16px' }}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
    </Box>
  );
};

const UsersEdit = () => {
  return (
    <>
      <BackButton label="Return to Users List" to="/users" />
      <Edit title={<UsersEditTitle />} mutationMode="pessimistic" undoable={false}>
        <SimpleForm toolbar={<CustomToolbar />}
          mode="onBlur"
          reValidateMode="onBlur"
          validate={validateUsersEdit}
        >
          <TextInput source="username" validate={[required()]} />
          <Labeled label="CAC Login Enabled">
            <TextField sx={{ pb: 1 }} source="hasEdipiHashYesNo" />
          </Labeled>

          {/* <SelectInput
            label="Role"
            source="role.roleId"
            choices={ROLES}
            optionValue="roleId"
            validate={[required()]}
          /> */}

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <SelectInput
                  label="Role"
                  source="role.roleId"
                  choices={ROLES}
                  optionValue="roleId"
                  validate={[required()]}
                // disabled={isRoleStudent(formData.role.name)}
                />
              )}
            </FormDataConsumer>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {/* <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.role.roleId === 2 && (
                  <SelectInput
                    label="Track"
                    source="track"
                    choices={INSTRUCTOR_TRACKS}
                    optionValue="name"
                    validate={[required()]}
                  // disabled={isRoleStudent(formData.role.name)}
                  />
                )
              }
            </FormDataConsumer> */}
          </div>

          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ReferenceInput
                source="organization.id"
                reference="organizations"
                sort={{ field: 'name', order: 'ASC' }}
              >
                <SelectInput
                  label="Organization"
                  optionText="name"
                  disabled={isRoleStudent(formData.role.name)}
                />
              </ReferenceInput>
            )}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.role.roleId === 2 && (
                <div style={{ display: 'flex' }}>
                  <Labeled sx={{ pb: 1 }} label="Track">
                    <TracksField source="tracks" label="Tracks" />
                  </Labeled>
                </div>
              )}
          </FormDataConsumer>

          <Labeled label="AppUser">
            <TextField sx={{ pb: 1 }} source="appUser.appUserAlias" />
          </Labeled>

          <ReferenceInput source="regStatus.lookupCodeId" reference="registrationstatuses">
            <SelectInput label="Registration Status" optionText="name" validate={required()} />
          </ReferenceInput>

          <ReferenceInput source="userStatus.lookupCodeId" reference="userstatuses">
            <SelectInput label="User Status" optionText="name" validate={required()} />
          </ReferenceInput>

          <TextInput source="email" validate={[required()]} />

          <PhoneNumberInput />

          <Labeled label="Approved By">
            <TextField sx={{ pb: 1 }} source="approvedBy" />
          </Labeled>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default UsersEdit;
