import React from 'react';
import {
  InfiniteList,
  InfinitePagination,
  useListContext,
  DatagridConfigurable,
  TextField,
  DateField,
  downloadCSV,
  TextInput,
  SelectInput,
  ExportButton,
  TopToolbar,
  BulkDeleteButton,
  useGetList,
  useRecordContext,
  SelectColumnsButton,
} from 'react-admin';
import TveTitle from '../Components/TveTitle';
import jsonExport from 'jsonexport/dist';
import { Box, Card, Typography, Chip, CircularProgress } from '@mui/material';
import { ROLES, LOGIN_ORGS } from '../Config/AuthProvider';
import { INSTRUCTOR_TRACKS } from '../Config/AuthProvider';

const exporter = (props) => {
  const userListForExport = props.map((user) => {
    // Omit all the fields inside the curly brackets
    const {
      id,
      edipiHash,
      role,
      organization,
      hasEdipiHash,
      hasEdipiHashYesNo,
      appUser,
      regStatus,
      userStatus,
      creationTimestamp,
      lastLogin,
      lastApiRequest,
      approvedBy,
      ...userListForExport
    } = user;

    // Add fields
    userListForExport.Role = user.role.name;
    userListForExport.Organization = user.organization?.name;
    userListForExport.CAC_LogIn_Enabled = user.hasEdipiHashYesNo;
    userListForExport.Registration_Status = user.regStatus.name;
    userListForExport.User_Status = user.userStatus.name;
    userListForExport.Creation_Timestamp = user.creationTimestamp;
    userListForExport.Approved_By = user.approvedBy;

    return userListForExport;
  });

  jsonExport(userListForExport, {}, (_err, csv) => {
    downloadCSV(csv, 'Users');
  });
};

const CustomPagination = () => {
  const { total } = useListContext();
  return (
    <>
      <InfinitePagination />
      {total > 0 && (
        <Box position="sticky" bottom={0} textAlign="center">
          <Card
            elevation={2}
            sx={{ px: 2, py: 1, mb: 1, display: 'inline-block', backgroundColor: 'lightblue' }}
          >
            <Typography variant="body2">{total} users</Typography>
          </Card>
        </Box>
      )}
    </>
  );
};

const BulkActionButtons = () => (
  <>
    <BulkDeleteButton
      sx={{
        color: 'white',
        backgroundColor: 'red',
        padding: '6px 11px',
        '&:hover': {
          backgroundColor: '#0066CC',
        },
      }}
    />
  </>
);

const roleChoicesArray = ROLES.map((role) => ({ id: role.name, name: role.name }));
const orgChoicesArray = LOGIN_ORGS.map((org) => ({ id: org.name, name: org.name }));
const userStatusChoicesArray = [
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'Inactive' },
];

const userFilters = [
  <TextInput label="Filter by Username" source="username" resettable alwaysOn />,
  <SelectInput
    label="Role"
    source="role.name"
    choices={roleChoicesArray}
    alwaysOn
    style={{ minWidth: '140px', width: '140px' }}
  />,
  // <SelectInput
  //   label="Track"
  //   source="track"
  //   choices={INSTRUCTOR_TRACKS.map((filter) => ({ id: filter.trackId, name: filter.name }))}
  //   alwaysOn
  //   style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
  // />,
  <SelectInput
    label="Organization"
    source="organization.name"
    choices={orgChoicesArray}
    alwaysOn
    style={{ minWidth: '140px', width: '140px' }}
  />,
  <SelectInput
    label="User Status"
    source="userStatus.name"
    choices={userStatusChoicesArray}
    alwaysOn
    style={{ minWidth: '130px', width: '130px' }}
  />,
  <TextInput label="Approved By" source="approvedBy" alwaysOn />,
];

const UsersListActions = () => (
  <TopToolbar>
    <ExportButton exporter={exporter} variant="contained" />
    <SelectColumnsButton variant="contained" />
  </TopToolbar>
);

const TracksField = () => {
  const record = useRecordContext();

  const { data, isLoading, error } = useGetList('tracks', {
    filter: { userid: record.id },
    sort: { field: 'name', order: 'ASC' }
  });

  if (isLoading) return <CircularProgress size="1rem" />;
  if (error) { return <span>error</span> };
  if (!data || data.length === 0) { return <span></span> };

  return (
    <div>
      {data.map((track) => (
        <Chip key={track.id}
          label={track.name}
          sx={{
            backgroundColor: '#D3D3D3'
          }}
        />
      ))}
    </div>
  );
};

const ConditionalTracksField = () => {
  const record = useRecordContext();
  if (record?.role?.name === 'INSTRUCTOR') {
    return <TracksField source="tracks" label="Tracks" />;
  }
  return <TextField source="tracks" label="Tracks" record={{ tracks: 'N/A' }} />;
};


const UsersList = () => (
  <InfiniteList
    pagination={<CustomPagination />}
    title={<TveTitle pageName="Users" />}
    filters={userFilters}
    sort={{ field: 'creationTimestamp', order: 'DESC' }}
    actions={<UsersListActions />}
  >
    <DatagridConfigurable bulkActionButtons={<BulkActionButtons />} rowClick="edit" omit={['id']}>
      <TextField source="id" label="id" />
      <TextField source="username" label="Username (click row to edit)" />
      <TextField source="role.name" label="Role" />
      <ConditionalTracksField label="Tracks" />
      <TextField source="organization.name" label="Organization" />
      <TextField source="regStatus.name" label="Registration Status" />
      <TextField source="userStatus.name" label="User Status" />
      <DateField source="creationTimestamp" label="Created Date" />
      <TextField source="email" label="Email" />
      <TextField source="phoneNumber" label="Phone Number" />
      <TextField source="approvedBy" label="Approved By" />
    </DatagridConfigurable>
  </InfiniteList>
);

export default UsersList;
